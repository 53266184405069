import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class UserService {
  token: string = 'Bearer ' + localStorage.getItem('token');
  constructor(private http: HttpClient) { }

  login(url: String, auth?: Boolean, params?: any): Observable<any> {
    this.token = 'Bearer ' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.post<any>(`${environment.apiUrl}${url}`, httpOptions);
    }
    else {
      return this.http.post<any>(`${environment.apiUrl}${url}`,params);
    }
  }
}
