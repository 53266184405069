import { UserService } from './../../_services/user.service';
import { first } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { auth } from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  loginForm: FormGroup;
  items: Observable<any[]>;
  public loading = false;

  constructor(private user:UserService,private toastr: ToastrService, private router: Router, private http: HttpClient) {
    // this.items = firestore.collection('items').valueChanges();
    console.log('items', this.items);
    this.loginForm = new FormGroup({
      correo: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', [Validators.required]),
    });
  }

  login() {
    this.loading = true;
    console.log(this.loginForm.value);
    this.user.login('admin/login', false, this.loginForm.value).pipe(first()).subscribe(data => {
      console.log('login', data);
      localStorage.setItem('user',JSON.stringify(data.user));
      localStorage.setItem('token',data.token);
      this.router.navigateByUrl('dashboard');
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log('resumenerror', err);
      this.toastr.warning(err.error);
    });
  }

  registrar() {
    this.router.navigate(['/register']);
  }
}
