import { Router } from '@angular/router';
import { Items } from './../../_models/items';
import { environment } from './../../../environments/environment';
import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
// import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {

  registroForm: FormGroup;
  // private itemsCollection: AngularFirestoreCollection<Items>;
  items: Observable<Items[]>;
  public loading = false;

  constructor(private toastr: ToastrService,
    private http: HttpClient,
    
    
    private router:Router
  ) {
    this.crearFormulario();
    // this.itemsCollection = firestore.collection<Items>('items');
    // this.items = this.itemsCollection.valueChanges();
  }

  private crearFormulario() {
    this.registroForm = new FormGroup({
      correo: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl(null, [Validators.required]),
      confirmarPassword: new FormControl(null, [Validators.required]),
    });
  }

  crearCuente() {
    this.loading = true;
    // this.fbAuth.auth.createUserWithEmailAndPassword(this.registroForm.controls['correo'].value, this.registroForm.controls['password'].value)
    //   .then(data => {
    //     this.fbAuth.auth.currentUser.sendEmailVerification().then(su => {
    //       console.log(su)
    //       this.loading = false;
    //       this.toastr.success('Hemos enviado un correo de verificación a tu cuenta de correo, no olvides revisar en spam');
    //       this.router.navigate(['login']);
    //     }, err => {
    //       this.loading = false;
    //       console.log(err)
    //     });
    //   }, err => { console.log(err); this.toastr.error(err.message); this.loading = false; });
  }
}
