// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // apiUrl:'http://localhost:3000/api/',
  apiUrl:'https://dev.latinosventas.ca/api/',
  // apiUrl:'https://api.latinosventas.ca/api/',
  production: false,
  firebase :{
    apiKey: "AIzaSyCheq7XWOkNZFT0TGqffg0dDYhyK5TM2EY",
    authDomain: "smsapi-b864e.firebaseapp.com",
    databaseURL: "https://smsapi-b864e.firebaseio.com",
    projectId: "smsapi-b864e",
    storageBucket: "smsapi-b864e.appspot.com",
    messagingSenderId: "824820172026",
    appId: "1:824820172026:web:f499501db1fba906c9f543",
    measurementId: "G-8XJLL0B5YR"
  }
};
